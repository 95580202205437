<template>
  <div class="home">
    <el-carousel height="600px" :interval="10000">
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <div class="home_swiper_wraper">
          <div class="home_swiper_title1">{{ item.title1 }}</div>
          <div class="home_swiper_title2">{{ item.title2 }}</div>
        </div>
        <div
          class="home_swiper_img"
          :style="{
            background: 'url(' + item.img + ') no-repeat center center'
          }"
          style="height: 600px"
        ></div>
      </el-carousel-item>
    </el-carousel>
    <div class="home_swiper">
      <div class="home_wraper_item">
        <div class="home_wraper_item_title">智慧政务高效工作平台</div>
        <div class="home_wraper_item_tip">
          告别信息孤岛,开启全链接一网式工作新模式
        </div>
        <div class="home_swiper_wrapper">
          <el-carousel height="600px" :autoplay="false" direction="vertical">
            <el-carousel-item v-for="(item, index) in list" :key="index">
              <div class="home_wraper">
                <div class="home_wraper_item_left">
                  <img
                    :src="item.icon"
                    alt=""
                    class="home_wraper_item_left_icon"
                  />
                  <div class="home_wraper_item_left_title">
                    {{ item.title }}
                  </div>
                  <div
                    class="home_wraper_item_left_detail"
                    v-html="item.detail"
                  >
                    <!-- {{ item.detail }} -->
                  </div>
                  <div class="home_wraper_item_left_desc">{{ item.desc }}</div>
                  <div class="home_wraper_item_left_content">
                    {{ item.content }}
                  </div>
                </div>
                <div class="home_wraper_item_right">
                  <img :src="item.img" alt="" />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="home_service">
      <div class="home_service_title">智慧政务综合服务平台</div>
      <div class="home_service_desc">
        构建全链接一网式服务新模式<br />
        政务服务前移，随时随地通过移动互联网连接党员、群众和企业
      </div>
      <div class="home_service_link">进一步了解综合服务 ></div>
      <div
        class="home_service_img"
        @mouseenter="imgEnter"
        @mouseleave="imgLeave"
      >
        <transition name="fade">
          <div v-if="index === 1">
            <div class="home_service_list">
              <div class="home_service_item">
                <div class="home_service_item_title">
                  <img src="@/assets/images/home/red.png" alt="" /> 党群连心
                </div>
                <div class="home_service_item_desc">
                  换个方式，密切党员和群众的关系
                </div>
              </div>
              <div class="home_service_item">
                <div class="home_service_item_title">
                  <img src="@/assets/images/home/green.png" alt="" /> 政民互动
                </div>
                <div class="home_service_item_desc">
                  群众线上监督、办事、反映民意，创新政民互动渠道
                </div>
              </div>
              <div class="home_service_item">
                <div class="home_service_item_title">
                  <img src="@/assets/images/home/blue.png" alt="" /> 政企互动
                </div>
                <div class="home_service_item_desc">
                  政企指尖交流，创造良好的营商环境
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="home_product_container">
      <div class="home_service home_product">
        <div class="home_service_title">每套产品都独一无二</div>
        <div class="home_service_desc">
          为政府量身定制个性产品，易上手，更实用
        </div>
        <div class="home_service_link">点击获取专属方案 ></div>
        <div class="home_product_wraper">
          <div class="home_product_wraper_left">
            <img src="@/assets/images/home/pro.png" alt="" />
          </div>
          <div class="home_product_wraper_right">
            <div class="home_product_wraper_right_ser">
              <img src="@/assets/images/home/prot.png" alt="" />
              <div class="home_product_wraper_ser_item">
                <div class="home_product_wraper_title">个性化定制技术服务</div>
                <div class="home_product_wraper_desc">
                  告别模板化系统，为你量身订造更高性价比的系统。
                </div>
              </div>
            </div>
            <div class="home_product_wraper_right_team">
              <img src="@/assets/images/home/pros.png" alt="" />
              <div class="home_product_wraper_ser_item">
                <div class="home_product_wraper_title">十年经验成熟团队</div>
                <div class="home_product_wraper_desc">
                  成熟的政务工作、服务平台与
                  技术服务三位一体高效交付高质量的产品
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_office">
      <div class="home_office_title">超800个单位 已进入智能化办公时代</div>
      <div class="home_office_wraper">
        <div
          class="home_office_item"
          v-for="(item, index) in card"
          :key="index"
        >
          <img :src="item.img" alt="" />
          <div class="home_office_item_wraper">
            <div class="home_office_item_title">{{ item.title }}</div>
            <div class="home_office_item_desc">{{ item.desc }}</div>
            <div class="home_office_item_detail">{{ item.detail }}</div>
          </div>
        </div>
      </div>
      <div class="home_office_link">看看他们怎么说 ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  props: {

  },
  data () {
    return {
      imgList: [
        {
          title1: '厚实软件',
          title2: '做最实用的软件',
          // img: require('@/assets/images/home/banner1.png')
          img: require('@/assets/images/home/首页banner1.png')
        },
        {
          title1: '30天构建区县党政、群众、企业',
          title2: '全链接一网式工作和服务新模式',
          img: require('@/assets/images/home/首页banner2.png')
        },
        {
          title1: '深耕智慧政务十年，更懂你的需要',
          title2: '',
          img: require('@/assets/images/home/首页banner3.png')
        },
        {
          title1: '个性定制  不做复制',
          title2: '',
          img: require('@/assets/images/home/首页banner4.png')
        }
      ],
      list: [
        {
          icon: require('@/assets/images/home/icon1.png'),
          title: '智能办文',
          detail: '为基层量身定制最适合区县级的办文系统',
          desc: '深耕智慧政务多年，熟悉政务运作流程，可按需求灵活设计办文工作流程。',
          content: '进一步了解智能办文   >',
          // img: require('@/assets/images/home/znbw.png')
          img: require('@/assets/images/home/01智慧办文.png')
        },
        {
          icon: require('@/assets/images/home/icon3.png'),
          title: '互动交流',
          detail: '构建同一张网上的信息文件安全共享交流平台',
          desc: '实现个人之间、单位之间信息、文件材料的快捷安全共享',
          content: '进一步了解互动交流   >',
          // img: require('@/assets/images/home/hdjl.png')
          img: require('@/assets/images/home/02互动交流.png')
        },
        {
          icon: require('@/assets/images/home/icon2.png'),
          title: '行政导航',
          detail: '征求意见、领导指令、上级交办等<br/>多部门高效协作，限时完成，GPS定位到人',
          desc: '结合绩效考核管理办法，保障领导交办的事项以及重点工作能够准时高效完成',
          content: '进一步了解行政导航   >',
          // img: require('@/assets/images/home/xzdh.png')
          img: require('@/assets/images/home/03行政导航.png')
        },
        {
          icon: require('@/assets/images/home/icon4.png'),
          title: '智能办会',
          detail: '一个流程轻松解决会前准备工作',
          desc: '构建高效的会议组织方式，提高会前准备工作效率，大幅减少跟会人员工作量',
          content: '进一步了解智能办会   >',
          // img: require('@/assets/images/home/znbh.png')
          img: require('@/assets/images/home/04智能办会.png')
        },
        {
          icon: require('@/assets/images/home/icon5.png'),
          title: '工作日程',
          detail: '更懂领导的“智能小助手”',
          desc: '为每位领导和工作人员都配备一名“智能小助手”，自动统筹安排所有的工作',
          content: '进一步了解工作日程   >',
          // img: require('@/assets/images/home/gzrc.png')
          img: require('@/assets/images/home/05工作日程.png')
        },
        {
          icon: require('@/assets/images/home/icon6.png'),
          title: '移动办公',
          detail: '摆脱办公室束缚',
          desc: '使用移动办公实现全天候办公，流程不耽搁，签批不等待。 ',
          content: '进一步了解移动办公   >',
          // img: require('@/assets/images/home/ydbg.png')
          img: require('@/assets/images/home/06智能办公.png')
        }
      ],
      // img: require('@/assets/images/home/serbg.png'),
      index: 0,
      card: [
        {
          img: require('@/assets/images/home/card1.png'),
          title: '佛山市高明区',
          desc: '区、镇、村200+单位全覆盖',
          detail: '区有关机关、区委工作部门、政府工作部门、各镇政府、街道办事处、村居、其他事业单位、学校、医院等。'
        },
        {
          img: require('@/assets/images/home/card2.png'),
          title: '佛山市三水区',
          desc: '区、镇、村200+单位全覆盖',
          detail: '区有关机关、区委工作部门、政府工作部门、各镇政府街道办事处、村居、其他事业单位。'
        },
        {
          img: require('@/assets/images/home/card3.png'),
          title: '延安市富县',
          desc: '区、镇、村200+单位全覆盖',
          detail: '县委工作部门、县政府工作部门、县有关机关各镇政府、街道办事处、村居、其他事业单位'
        }
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    imgEnter () {
      this.index = 1
    },
    imgLeave () {
      this.index = 0
    }
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
